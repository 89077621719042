import { useEffect, useRef, useState } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

import { ChatHistory, ChatHistoryType } from '@/components/layout/Chat/ChatHistory'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { useAuthContext } from '@/context/AuthContext'
import { BasicModal } from '@/components/uis/Modal/BasicModal'
import { BasicButton } from '@/components/uis/Button/BasicButton'
import { useInfoContext } from '@/context/InfoConrtext'

import { dateConverter } from '@/utils/helpers/convertDateFormat'
import { useIsMobileOrTablet } from '@/utils/hooks/useIsMobileOrTablet'
import { useSnackbarContext } from '@/context/SnackbarContext'
import { ChatSquareIcon } from '@/components/icon/ChatSquareIcon'
import { fontWeight, fontSize, bgColor } from '@/utils/themeConfigs/customTheme'
import { useGetPrompt } from '@/features/user/prompt/hooks/useGetPrompt'
import { getChatRoomId } from '@/utils/helpers/getChatRoomId'
import { QueryKeys } from '@/utils/apis/api-keys'

import { ChatWelcome } from '../ui/chat/ChatWelcome'
import { ChatForm } from '../ui/chat/ChatForm'
import { useChatLogic } from '../../hooks/useChatLogic'
import { useLlmModel } from '../../hooks/useLlmModel'
import { useFetchChatRoom } from '../../hooks/useFetchChatRoom'
import { useSpeechRecognition } from '../../hooks/useSpeechRecognition'
import { DenyIndexAndFileModal } from '../ui/chat/DenyIndexAndFileModal'

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 8px 32px 8px;
  display: flex;
  flex-direction: column;

  ${mediaQuery('tab')} {
    padding: 0;
    height: 100%;
    min-height: 100%;
    position: relative;
  }
`

const Main = styled('div')<{ dynamicPadding: number }>`
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 0;
  overflow: hidden;
  ${mediaQuery('tab')} {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: ${({ dynamicPadding }) => `${dynamicPadding}px`};
  }
`
const PromptInfoContainer = styled(Box)`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
`

const PromptContent = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 32px 0;
  ${mediaQuery('tab')} {
    padding: 0 16px;
    margin: 16px 0 0;
  }
`

const PromptIcon = styled(Box)``

const PromptTitle = styled(Typography)`
  font-size: ${fontSize.xl};
  font-weight: ${fontWeight.bold};
`

const DialogContent = styled(Box)`
  font-size: 16px;
  font-weight: 700;
`

const ItemContainer = styled('div')`
  max-width: 358px;
  display: flex;
  gap: 18px;
  padding: 16px 0 0;
  margin: 0 auto;
`
const ChatBox = styled(Box)`
  ${mediaQuery('tab')} {
    position: fixed;
    bottom: 64px;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: ${bgColor.white};
  }
`

type HandleSendChatArgumentType = {
  message?: string
  callback?: () => void
  isVoiceInput?: boolean
  handleReadText?: (text: string) => void
}

export type HandleSendChatType = (param?: HandleSendChatArgumentType) => void

export const Chat = () => {
  const searchParams = useSearchParams()
  const queryClient = useQueryClient()
  const promptId = searchParams.get('id') ?? ''
  const isMobile = useIsMobileOrTablet()
  const { user } = useAuthContext()
  const token = user?.token ?? ''
  const { modal, infoSummary, handleModalClose, pageTransition } = useInfoContext()

  // クエリ受け取ってChatHistoryにセットする
  const router = useRouter()
  const pathname = usePathname()
  const chatRoomId = getChatRoomId({ router, pathname })
  const { showSnackbar } = useSnackbarContext()
  const { data, error } = useFetchChatRoom({ token, chatRoomId })
  const { data: promptData, error: promptError } = useGetPrompt(
    token,
    promptId ?? (data !== undefined ? data[0].original_prompt_id : ''),
  )

  const {
    prompt,
    setPrompt,
    checkedIndex,
    selectedFile,
    setSelectedFile,
    chatInputValue,
    handleChangeChatInput,
    maxLength,
    isValid,
    chatHistory,
    sendChat,
    isAnswering,
    syncChatHistory,
    initializeChatHistory,
    initializeChatInput,
    initializeCheckedIndex,
    initializeFile,
    isDenyIndexAndFileOpen,
    handleDenyIndexAndFileClose,
  } = useChatLogic()

  const {
    selectedLlmModel,
    allowFileAttachments,
    llmModels,
    changeLlmModel,
    handleModalSubmit,
    isOpen,
    handleClose,
    isIndexModalOpen,
    handleIndexModalOpen,
    handleIndexModalClose,
    changeLlmModelFromId,
  } = useLlmModel({
    token,
    initializeChatHistory,
    isExceptImageModel: !!promptId || (data && !!data[0].original_prompt_id),
  })

  // initializeChatより後にsearchParamsが変化する
  // ここでプロンプト情報を初期化する
  useEffect(() => {
    if (!searchParams.get('id')) {
      queryClient.removeQueries({ queryKey: QueryKeys.prompt })
      if (!chatRoomId) initializeChat()
    }
  }, [searchParams])

  useEffect(() => {
    if (data) {
      // 新規チャット後のチャットルーム遷移はIndexを除外しない
      if (chatHistory[0]?.chatLogId) {
        initializeCheckedIndex()
      }

      syncChatHistory(data)
      if (data[0].original_prompt_id && data[0].original_prompt_name) {
        setPrompt({ id: data[0].original_prompt_id, title: data[0].original_prompt_name })
      } else {
        setPrompt(undefined)
      }

      changeLlmModelFromId(data[0].llm_model_id)
      initializeFile()
      initializeChatInput()
    } else {
      // プロンプト情報があればプロンプトチャットとして初期表示を設定する
      if (promptData) {
        setPrompt({ id: promptData.prompt_id, title: promptData.prompt_name })
        if (promptData.template) initializeChatInput(promptData.template)
        if (promptData.initial_message) {
          const initHistory = [
            {
              message: promptData.initial_message,
              role: 'assistant',
            },
          ] as ChatHistoryType
          initializeFile()
          initializeChatHistory(initHistory)
        }
      } else if (!prompt) {
        setPrompt(undefined)
        initializeFile()
        initializeChatInput()
        initializeChatHistory()
      }
    }
  }, [data, changeLlmModelFromId, syncChatHistory, setPrompt, promptData])

  useEffect(() => {
    // 履歴が削除済みならHOMEチャットに戻す
    if (error?.response?.status === 404) {
      void (async () => {
        await router.push('/')
      })()
    } else if (promptId && promptError?.response?.status === 400) {
      // TODO: 取得失敗のレスポンス形式を把握する
      // TODO: error?.response? がundefinedな理由を探す
      showSnackbar?.({
        text: 'プロンプトの取得に失敗しました',
        severity: 'error',
      })
      void (async () => {
        await router.push('/')
      })()
    }
  }, [error, router, promptError])

  // 音声入力などstate更新せずに送信したい時があるので送るメッセージを外から入れれるようにした
  const handleSendChat = ({
    message,
    callback,
    isVoiceInput,
    handleReadText,
  }: HandleSendChatArgumentType = {}) => {
    ;(async () => {
      // ここがundefinedになる場合は別の箇所でトーストが表示されるのでここでは処理を行わない
      if (!token || !selectedLlmModel) return

      await sendChat({
        token,
        model_id: selectedLlmModel.id,
        model_group: selectedLlmModel.group,
        chat_room_id: chatRoomId,
        original_prompt_id: prompt?.id,
        index_list: checkedIndex,
        message,
        isVoiceInput,
        handleReadText,
        handleIndexModalOpen,
      })

      callback?.()
    })().catch(() => {
      showSnackbar?.({
        text: 'エラーが発生しました。時間をおいて再度お試しください。',
        severity: 'error',
      })
    })
  }

  const { isSpeaking, isRecording, startRecording, stopRecording } = useSpeechRecognition(
    {
      pathname,
      handleSendChat,
      handleChangeChatInput,
      chatInputValue,
    },
  )

  const initializeChat = () => {
    // llmModelの初期化はuseLlmModel.tsxで行う
    initializeChatHistory()
    initializeChatInput()
    initializeCheckedIndex()
    initializeFile()
    stopRecording()
    setPrompt(undefined)
  }

  const chatInputContainerRef = useRef<HTMLDivElement>(null)
  const [dynamicPadding, setDynamicPadding] = useState(156)

  useEffect(() => {
    if (!chatInputContainerRef.current) return
    const observer = new ResizeObserver(() => {
      if (chatInputContainerRef.current) {
        const newPadding = chatInputContainerRef.current.offsetHeight
        setDynamicPadding((prev) => (prev !== newPadding ? newPadding : prev))
      }
    })
    observer.observe(chatInputContainerRef.current)

    setDynamicPadding(chatInputContainerRef.current.offsetHeight)

    return () => observer.disconnect()
  }, [chatInputValue])

  return (
    <>
      <Container>
        <Main dynamicPadding={dynamicPadding}>
          {!!prompt && (
            <PromptInfoContainer>
              <PromptContent>
                <PromptIcon>
                  <ChatSquareIcon />
                </PromptIcon>
                <PromptTitle>{prompt?.title} </PromptTitle>
              </PromptContent>
            </PromptInfoContainer>
          )}
          {chatHistory.length === 0 ? (
            <ChatWelcome />
          ) : (
            <ChatHistory chatHistory={chatHistory} isAnswering={isAnswering} />
          )}
        </Main>
        <ChatBox ref={chatInputContainerRef}>
          <ChatForm
            handleSendChat={handleSendChat}
            chatInputValue={chatInputValue}
            handleChangeChatInput={handleChangeChatInput}
            maxLength={maxLength}
            isValid={isValid}
            changeLlmModel={changeLlmModel}
            selectedLlmModel={selectedLlmModel}
            allowFileAttachments={allowFileAttachments}
            llmModels={llmModels || []}
            isAnswering={isAnswering}
            isOpen={isOpen}
            isMobile={isMobile}
            visibleTextExport={
              !!chatRoomId &&
              !!selectedLlmModel &&
              selectedLlmModel.id !== 90 &&
              chatHistory.length > 0
            }
            handleCloseModal={handleClose}
            handleModalSubmit={handleModalSubmit}
            isSpeaking={isSpeaking}
            isRecording={isRecording}
            startRecording={startRecording}
            stopRecording={stopRecording}
            promptId={prompt?.id}
            isIndexModalOpen={isIndexModalOpen}
            handleIndexModalClose={handleIndexModalClose}
            selectedIndex={checkedIndex.length > 0}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        </ChatBox>
      </Container>
      {modal && (
        <BasicModal
          wrapperWidth='large'
          innerWidth='large'
          open={modal.isOpen}
          onClose={handleModalClose}
          title='新しいお知らせがあります'
        >
          <>
            <DialogContent>
              {dateConverter(infoSummary?.created_at ?? '', 'long', '.')}{' '}
              {infoSummary?.title}
            </DialogContent>
            <ItemContainer>
              <BasicButton
                onClick={handleModalClose}
                label='閉じる'
                variant='outlined'
                width='140px'
                height='47px'
              />
              <BasicButton
                label='一覧へ'
                width='140px'
                height='47px'
                onClick={pageTransition}
              />
            </ItemContainer>
          </>
        </BasicModal>
      )}
      <DenyIndexAndFileModal
        open={isDenyIndexAndFileOpen}
        onClose={handleDenyIndexAndFileClose}
      />
    </>
  )
}
