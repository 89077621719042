import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { MutationKeys } from '@/utils/apis/api-keys'
import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'
import { Role } from '@/components/layout/Chat/ChatBubble'
import { useAuthContext } from '@/context/AuthContext'

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: AxiosError
  }
}

type UseFetchChatRoomArgument = {
  token: string
  chatRoomId: string | undefined
}

export type ImageChatContentType = {
  role: 'assistant_with_image' | 'user_with_image'
  content: {
    image: string[]
    text: string
  }
}

export type TextChatContentType = {
  user_name: string | undefined
  role: Role
  content: string
  chat_log_id: string
  feedback_id: string
  file_name: string | undefined
}

export type ChatContentType = ImageChatContentType | TextChatContentType

export type ChatLogType = [
  {
    id: string
    function_type: number
    original_prompt_id: string | null
    original_prompt_name: string | null
    initial_message: string | null
    llm_model_id: number
    title: string | null
  },
  ChatContentType[],
]

export const useFetchChatRoom = ({ token, chatRoomId }: UseFetchChatRoomArgument) => {
  const { apiClient } = useApiClient()
  const client = apiClient(token)
  const { controller } = useAuthContext()

  return useQuery({
    queryKey: [...MutationKeys.fetchChatHistory, chatRoomId],
    queryFn: () =>
      client
        .get<ChatLogType>(`${API.history}/${chatRoomId ?? ''}`, {
          signal: controller?.signal,
        })
        .then((res) => res.data),
    enabled: !!token && !!chatRoomId,
    retry: false,
  })
}
