import { BasicButton } from '@/components/uis/Button/BasicButton'
import { BasicModal } from '@/components/uis/Modal/BasicModal'
import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

const ModalFooter = styled(Box)`
  display: flex;
  gap: 16px;
  padding: 16px 0 0 0;
  justify-content: center;
`

const ModalText = styled(Typography)`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.448;
`
type Props = {
  open: boolean
  onClose: () => void
}

export const DenyIndexAndFileModal = ({ open, onClose }: Props) => {
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      wrapperWidth='ml'
      innerWidth='ml'
      title='ファイル添付とソースを併用したチャットはできません'
    >
      <>
        <ModalText>
          <>
            ファイル添付とソースは併用できません。
            <br />
            ソース選択を全て解除、または添付したファイルを削除した上でチャットしてください。
          </>
        </ModalText>
        <ModalFooter>
          <BasicButton
            label='閉じる'
            variant='outlined'
            width='140px'
            onClick={onClose}
          />
        </ModalFooter>
      </>
    </BasicModal>
  )
}
